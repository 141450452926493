@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "background-color": map-merge(
      map-get($utilities, "background-color"),
      (
        values: map-merge(
          map-get(map-get($utilities, "background-color"), "values"),
          (
            score-perfect: $green,
            score-excellent: $green-300,
            score-good: $yellow,
            score-acceptable: $orange,
            score-unacceptable: $danger,
          )
        )
      )
    ),
    "width": map-merge(
      map-get($utilities, "width"),
      (
        values: map-merge(
          map-get(map-get($utilities, "width"), "values"),
          (
            5: 5%,
            10: 10%,
            15: 15%,
            30: 30%,
          ),
        ),
      ),
    ),
  )
);

$grid-breakpoints: map-merge(
  $grid-breakpoints,
  (
    xxl: 1600px,
  ),
);

$container-max-widths: map-merge(
  $container-max-widths,
  (
    xxl: 1660px,
  ),
);

$theme-colors: map-merge(
  $theme-colors,
  (
    cta: $orange,
  ),
);

@import "bootstrap/scss/utilities/api";
