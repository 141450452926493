@import "bootstrap_variables";
@import "bootstrap_utilities";
@import "bootstrap/scss/bootstrap";

$call-to-action-color: map-get($theme-colors, "cta");

@import "common";

:root {
  @each $_key, $_value in $colors {
    --#{$prefix}#{$_key}-subtle: #{tint-color($_value, 80%)};
  }
}

body {
  --component-bg: var(--#{$prefix}body-bg);
}

@include color-mode(dark) {
  $_colors: (
    'blue': #0a84ff,
    'green': #00871d,
    'red': #eb0e24
  );

  $_theme-colors: (
    'primary': map-get($_colors, 'blue'),
    'success': map-get($_colors, 'green'),
    'danger': map-get($_colors, 'red')
  );

  @each $_key, $_value in map-merge($colors, $_colors) {
    --#{$prefix}#{$_key}: #{$_value};
    --#{$prefix}#{$_key}-subtle: #{shade-color($_value, 60%)};
  }

  @each $_key, $_value in map-merge($theme-colors, $_theme-colors) {
    --#{$prefix}#{$_key}: #{$_value};
    --#{$prefix}#{$_key}-rgb: #{to-rgb($_value)};
    --#{$prefix}#{$_key}-bg-subtle: #{shade-color($_value, 80%)};
    --#{$prefix}#{$_key}-border-subtle: #{shade-color($_value, 60%)};
    --#{$prefix}#{$_key}-text-emphasis: #{tint-color($_value, 40%)};
  }

  $_link-color: map-get($_theme-colors, 'primary');
  $_link-hover-color: shift-color($_link-color, -$link-shade-percentage);

  --#{$prefix}link-color: #{$_link-color};
  --#{$prefix}link-color-rgb: #{to-rgb($_link-color)};
  --#{$prefix}link-hover-color: #{$_link-hover-color};
  --#{$prefix}link-hover-color-rgb: #{to-rgb($_link-hover-color)};
}
