.fields-container {
  .remove-fields-container {
    padding-top: 7px;

    .remove-question,
    .remove-answer {
      color: $dark;
      cursor: pointer;
    }
  }
}


body.task_templates {
  .task-template-form {
    ul.questions {
      counter-reset: questions;

      li.question .counter::before {
        counter-increment: questions;
        content: counter(questions) ".";
      }
    }
  }
}
