.lines_of_business.show .lob-container {
  .bg-gray {
    background: $dashboard-template-bg-color;
  }

  li {
    line-height: 2.2rem;
  }

  .collapse-header {
    .counter {
      padding-left: 20px;
      color: var(--#{$prefix}primary);
      font-weight: $font-weight-normal;
    }

    .glyphicon {
      margin-right: 4px;
    }

    a {
      font-weight: $font-weight-bold;
      color: var(--#{$prefix}body-color);

      &:not(.collapsed) {
        .counter,
        .glyphicon-chevron-down { display: none; }
      }

      &.collapsed {
        .glyphicon-chevron-up { display: none; }
      }
    }
  }

  .nav-tabs {
    border-bottom: 2px solid $nav-tabs-border-color;

    .nav-link {
      &.active {
        color: $primary;
        border-top: none;
        border-right: none;
        border-left: none;
        font-weight: $font-weight-bold;
        text-decoration-line: underline;
        text-decoration-thickness: 4px;
        text-decoration-color: $primary;
        text-underline-offset: 14px;
      }
    }
  }

  .tab-content {
    padding: 0 map-get($spacers, 3);
    border-bottom: 2px solid $nav-tabs-border-color;
  }

  .table {
    thead > tr > th,
    tbody > tr > td {
      border: none;
      padding-left: 0;
    }
  }

}
