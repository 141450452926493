@import "./gridstack.scss";

$portlet-gutter-padding: $gridstack-gutter-padding;
$portlet-icon-size: 14px;

.portlet {
  @include gridstack-portlet-gutters;

  &__content-position-container {
    @extend .w-100, .h-100;
    position: relative;
  }

  &__edit-mode-overlay {
    @extend .h-100, .w-100;
    display: none;
    flex-direction: column;
    justify-content: space-between;

    cursor: move;

    position: absolute;
    top: 0;
    left: 0;

    background-color: rgba(255, 255, 255, 0.75);
    transition: background-color 0.35s;

    &.portlet__edit-mode-overlay--locked {
      cursor: inherit;
    }

    &.portlet__edit-mode-overlay--unfocused {
      background-color: rgba(240, 240, 240, 0.75);
    }
  }

  @mixin portlet-icon-always-visible {
    position: absolute;
    z-index: 1;
    top: unset;
    @extend .mx-2; // instead of mx-1, because it ignores outer mx-1 padding of edit overlay
  }

  &__icon {
    @extend .mx-1, .my-2;
    width: $portlet-icon-size;
    height: $portlet-icon-size;
    cursor: pointer;

    &.portlet__icon-placeholder {
      visibility: hidden;
    }

    &.lock {
      opacity: 0.3;
    }
    &.lock--locked {
      opacity: 1;
    }

    &.configuration {
      @include portlet-icon-always-visible;
      top: 0;
      right: 0;
    }

    &.loading {
      @include portlet-icon-always-visible;
      bottom: 0;
      left: 0;
    }
  }

  .no-data-container {
    display: table;
    width: 100%;
    height: 100%;

    .no-data {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: $font-size-lg;
    }
  }
}

.dashboard.configurable {
  .portlet {
    &__edit-mode-overlay {
      display: flex;
    }
  }
}

form.edit_portlet {
  .nested-fields {
    margin-bottom: 20px;

    a.remove_fields {
      color: $dark;
    }

    .toggle-sort-direction {
      cursor: pointer;

      &.glyphicon-sort {
        opacity: 0.2;

        &:hover {
          opacity: 1.0;
        }
      }
    }

    .glyphicon-info-sign {
      top: 2px;
    }

    .col-form-label {
      font-weight: $font-weight-normal;
      display: inline-block;
      text-align: right;
    }
  }

  .nested-fields:not(.nested-fields--bootstrap) {
    .form-control,
    .form-select {
      &.nested-select + .select2-container {
        max-width: 50%;
      }
    }

    .numeric,
    .model,
    .operator,
    .string,
    .select2-container--bootstrap {
      display: inline-block;
    }
  }

  .widget-filters {
    .col-form-label {
      margin-right: 5px;
    }
  }

  .col-form-label {
    font-weight: $font-weight-bold;
  }

  .measures .measures-fields {
    max-height: 235px;
    overflow-y: auto;
    margin-bottom: 20px;

    .nested-fields {
      &.last, &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .widget-filters {
    .dropdown {
      display: inline-block;
    }
  }
}

.widget-configuration {
  .btn-close {
    position: absolute;
    top: 3px;
    right: 6px;
  }

  dl {
    margin-bottom: 0;
    max-width: 240px;
  }

  dt {
    text-align: left;
    padding-left: 0;
  }

  dd {
    margin-bottom: 0;
  }

  dt, dd {
    flex: none;
    max-width: none;
  }

  dd + dt {
    margin-top: 15px;
  }
}
