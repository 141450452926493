.breadcrumb {
  &,
  .breadcrumb-item,
  .breadcrumb-item > label {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .breadcrumb-item {
    max-width: 300px;
    white-space: nowrap;

    &::before {
      @extend %glyphicon;
      font-size: $icon-size-sm;
    }

    & > * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
