body.scrape_experiences.index:has(.beta-ui) {
  --#{$prefix}body-bg: var(--#{$prefix}tertiary-bg);
  --component-bg: var(--#{$prefix}white);
  --indicator-width: 5px;

  #main-nav { --main-nav-bg: var(--#{$prefix}white); }

  .value-or-empty:empty::before { content: '-'; }

  // @TODO: Overriding the old styles temporarily. Remove this after the old style is removed.
  #unbiased-perspective,
  .page-header--responsive {
    display: none !important;
  }

  #metadata,
  #outputs {
    .card { --#{$prefix}card-border-width: 0; }
  }

  // @TODO: Overriding the input metadata component. Fix each component and remove this.
  #metadata {
    $_card-spacer: map-get($spacers, 4);

    .card {
      --#{$prefix}card-spacer-x: #{$_card-spacer};
      --#{$prefix}card-cap-padding-x: #{$_card-spacer};

      .card-header { --#{$prefix}card-cap-padding-x: 0; }
      .card-footer {
        --#{$prefix}card-cap-bg: var(--component-bg);
        --#{$prefix}card-cap-padding-y: #{map-get($spacers, 3)};
        border-top: 1px solid var(--#{$prefix}border-color);
      }
    }

    .list-group {
      $_list-group-item-spacer: $_card-spacer;
      --#{$prefix}list-group-border-width: 0;
      --#{$prefix}list-group-item-padding-x: #{$_list-group-item-spacer};

      .list-group-item {
        position: relative;

        &:first-child { margin-top: $_list-group-item-spacer; }
        &:last-child { margin-bottom: $_list-group-item-spacer; }

        .indicator {
          position: absolute;
          top: var(--#{$prefix}list-group-item-padding-y);
          bottom: var(--#{$prefix}list-group-item-padding-y);
          left: 0;
          width: var(--indicator-width);
        }

        > *:last-child { margin-bottom: 0; }
      }
    }

    .input-metadata-component {
      > .list-group > .list-group-item > * { margin-bottom: 0 !important; }

      .output-media__image-container,
      .output-media__image-container--responsive {
        width: 100%;
        display: block !important;

        img {
          width: 80px;
          margin-bottom: map-get($spacers, 3);
        }
      }

      .output-media__metadata-container,
      .output-media__metadata-container--responsive {
        margin: 0 !important;
        font-size: $font-size-sm;

        h4 {
          margin: 0;
          margin-bottom: map-get($spacers, 1) !important;
          font-size: $font-size-base;
          font-weight: $font-weight-bold;
        }
      }

      .key-value-table {
        margin-bottom: 0 !important;

        .list-group-item:first-child { margin-top: 0 !important; }
        .list-group-item:last-child { margin-bottom: 0 !important; }
        .key-value-table__header { display: none; }
        .row { margin: 0; }

        .list-group-item {
          padding-left: 0;
          padding-right: 0;
        }

        .key,
        .value {
          text-align: left !important;
          width: 100%;
          padding: 0 !important;
        }
      }
    }
  }

  // @TODO: Overriding the output media. Fix each template and remove this.
  #outputs {
    .output-media__image-container,
    .output-media__image-container--responsive {
      $_size: 64px;

      width: auto;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-right: map-get($spacers, 4) !important;

      .output-media__unavailable-image-container {
        width: $_size;
        height: $_size;
      }

      img { width: $_size; }
    }

    .output-media__metadata-container,
    .output-media__metadata-container--responsive {
      margin: 0 !important;
      font-size: $font-size-sm;

      [class*="mb-"] { margin-bottom: 0 !important; }
      [class*="mt-"] { margin-top: 0 !important; }

      h4 {
        margin: 0;
        margin-bottom: map-get($spacers, 1) !important;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
      }
    }

    .output-media__audio-container .mejs__audio { height: 32px !important; }
  }
}
