.auto_graders.show,
.auto_graders.edit,
.auto_graders.update,
.auto_raters_questions_auto_graders.new,
.auto_raters_questions_auto_graders.create
{
  .collapse-header {
    a {
      color: var(--#{$prefix}body-color);

      &:not(.collapsed) {
        .glyphicon-chevron-right { display: none; }
      }

      &.collapsed {
        .glyphicon-chevron-down { display: none; }
      }
    }
  }
}
