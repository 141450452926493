#input-task-counts {
  .metric-visual {
    .side-preference-score { min-width: 160px; }
    .progress { min-width: 80px; }
  }

  th.input-name,
  td.input-name {
    width: 25%;
  }

  .task-count {
    width: 10%;
  }
}

#scrape-counts {
  th.input-name,
  td.input-name {
    width: 25%;
  }

  .task-count {
    width: 20%;
  }
}
