$prefix: bs-;

$black: #000;
$white: #fff;
$gray-900: #222;
$gray-800: #333;
$gray-700: #555;
$gray-600: #8e8e93;
$gray-400: #c4c4c7;
$gray-200: #e9ecef;
$gray-100: #f6f6f6;

$blue: #0066d6;
$indigo: #5856d6;
$purple: #af52de;
$pink: #ff2d55;
$red: #de071c;
$orange: #ff9500;
$yellow: #ffcc00;
$green: #007d1b;
$teal: #59adc4;
$cyan: #55bef0;

$primary: $blue;

$grid-gutter-width: 30px;

$link-decoration: none;
$line-height-base: 1.5;
$line-height-sm: $line-height-base;
$line-height-lg: $line-height-base;

$font-family-sans-serif: -apple-system, system-ui, -webkit-system-font, Helvetica, Arial, san-serif;
$font-size-root: 14px;
$font-size-base: 1rem;
$form-text-color: $gray-600;
$form-text-font-size: $font-size-base;

$headings-font-weight: 700;
$headings-line-height: 1.1;

$navbar-padding-y: 0;
$nav-link-font-size: $font-size-base;

$input-bg: var(--component-bg);
$input-btn-padding-y: 0.6rem;
$input-btn-padding-x: $input-btn-padding-y * 2;
$input-btn-padding-y-sm: 0.35rem;
$input-btn-padding-x-sm: $input-btn-padding-y-sm * 2;
$input-group-addon-bg: $gray-200;

$table-bg: var(--component-bg);
$table-cell-padding-y-sm: 0.3rem;
$table-cell-padding-x-sm: 0.3rem;
$table-group-separator-color: var(--#{$prefix}border-color);

$dropdown-bg: var(--component-bg);
$card-bg: var(--component-bg);
$list-group-bg: var(--component-bg);
$pagination-bg: var(--component-bg);
$modal-content-bg: var(--component-bg);

$progress-height: 10px;
$progress-bg: transparent;

$hr-opacity: 0.1;

$min-contrast-ratio: 2;

$nav-link-focus-box-shadow: none;
$nav-tabs-link-active-bg: var(--component-bg);

$breadcrumb-divider: quote("\e258");
$breadcrumb-divider-color: var(--#{$prefix}body-color);
