$neutral-dark--presentation: var(--#{$prefix}gray-800);
$running-gradient: linear-gradient(to right, var(--#{$prefix}success), color-mix(in srgb, var(--#{$prefix}success), black 10%));
$neutral-gradient--presentation: linear-gradient(to right, $neutral-dark--presentation, color-mix(in srgb, $neutral-dark--presentation, black 10%));

$data-container-height: 100px;

body.plan_experiments.show,
body.plan_experiments.start,
body.plan_experiments.new {
  background: $page-bem-bg-color;
}

.experiments.edit, .experiments.update, .experiments.new, .experiments.create, .treatments.edit, .treatments.update {
  .nested-fields {
    div.remove-fields {
      position: absolute;
      right: -5px;
    }

    .remove-fields a {
      color: $dark;
    }

    .vs {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
      height: 35px;
      width: 35px;
    }
  }

  [data-dynamic-fields-for] { display: none; }
}

.ab-experiment-form, .treatments.edit {
  .form__subheader {
    @extend .col-12;
    @extend .pt-4, .pb-2;

    &--without-table {
      padding-left: 0;
      @extend .col-md-8;
      @extend .offset-md-4;

      @include media-breakpoint-up(md) {
        padding-left: 0.5rem;
      }
    }

    &--with-table {
      @include media-breakpoint-up(md) {
        text-align: center;
      }
    }
  }
}

#available-traffic {
  .timeframe-overlap-cell {
    border-right: 1px solid $table-border-color;
    border-left: 1px solid $table-border-color;
    padding-left: 0;
    padding-right: 0;
  }

  .timeframe-overlap {
    background-color: $primary;
    box-sizing: border-box;
  }

  .radius-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .radius-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.experiments.running_and_upcoming, .experiments.running_and_upcoming_presentation {
  tbody {
    border-top: 2px solid $gray-600;
    border-bottom: 2px solid $gray-600;
  }
  td.status::after {
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    margin: 0 6px 0 4px;
    border-radius: 50%;
    background-image: none;
  }
  td.status--running::after {
    background-image: $running-gradient
  }
}

.running_and_upcoming_presentation {
  table.table-hover tbody tr:hover {
    background-color: $neutral-dark--presentation;
  }

  thead::after {
    content: '';
    display: block;
    height: -1px;
  }
}


.running_and_upcoming_presentation {
  .data-container {
    background-image: $neutral-gradient--presentation;
    border-radius: 10px;
    height: $data-container-height;
    min-width: $data-container-height * 2;
    box-sizing: border-box;
    padding: 15px 30px;
    margin: 0 10px 10px 0;
    font-weight: $font-weight-bold;
    line-height: 18px;

    h1, h4, span {
      font-weight: $font-weight-bold;
      margin: 0;
    }
    h1 {
      font-size: map-get($display-font-sizes, 1);
    }
    span {
      font-size: $small-font-size;
    }

    .wrap-title {
      max-width: 110px;
    }

    &--lg {
      min-width: $data-container-height * 3;
    }
    &--xl {
      min-width: $data-container-height * 4.5;
    }
  }

  .data-container__column-layout-wrapper {
    overflow: hidden;
    width: 100%;
  }
}

.treatments__container {
  overflow-y: auto;
  max-height: 500px;
}

.plans-experiment-sizing-section {
  max-height: 75vh;
  overflow-y: scroll;
}

#experiment-storefronts-card {
  max-height: 40vh;
  overflow-y: auto;
}
