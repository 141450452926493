#endpoint-params, #endpoint-headers {
  td {
    border-top: 0;
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }

  td.name {
    width: 45%;
  }

  td.separator {
    width: 10%;
    text-align: center;
    vertical-align: middle;
  }

  td.value {
    position: relative;
    width: 45%;
    overflow: visible;

    .remove-endpoint-param, .remove-endpoint-header {
      position: absolute;
      top: 12px;
      right: -20px;
      color: $dark;
    }
  }
}

#sample-url {
  font-family: Menlo, monospace;
  font-size: $font-size-sm;
  line-height: 20px;
}

#post-body-change-confirmation {
  pre {
    max-height: 30vh;
  }
}

.experiments.new,
.experiments.create,
.experiments.edit,
.experiments.update,{
  form {
    .nested-fields {
      a.remove_fields {
        color: $dark;
      }

      .nested-select {
        + .select2-container {
          max-width: 60%;
        }
      }

      .select2-container--bootstrap {
        display: inline-block;
      }
    }
  }
}
